export const balanceChangeReasonCode = {
  fines: 'fines',
  bonuses: 'bonuses',
  manualPrePayment: 'manual pre-payment',
  transferFromOneAgentAnother: 'transfer from one agent to another',
  transactionsThatWereAccountedForIncorrectly:
    'transactions that were accounted for incorrectly',
  syncOfBalanceBetweenAgentAndClientMostbet:
    'sync of balance between agent and client Mostbet',
};

export const balanceChangeReasons = {
  [balanceChangeReasonCode.manualPrePayment]: {
    label: 'Manual pre-payment',
    value: balanceChangeReasonCode.manualPrePayment,
  },
  [balanceChangeReasonCode.fines]: {
    label: 'Fines',
    value: balanceChangeReasonCode.fines,
  },
  [balanceChangeReasonCode.bonuses]: {
    label: 'Bonuses',
    value: balanceChangeReasonCode.bonuses,
  },
  [balanceChangeReasonCode.transferFromOneAgentAnother]: {
    label: 'Transfer from one agent to another',
    value: balanceChangeReasonCode.transferFromOneAgentAnother,
  },
  [balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly]: {
    label: 'Transactions that were accounted for incorrectly',
    value: balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
  },
  [balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet]: {
    label: 'Sync of balance between agent and client Mostbet',
    value: balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
  },
};

export const balanceChangeReasonDictionary = Object.values(
  balanceChangeReasons
).filter(
  ({ value }) =>
    value !== balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet
);

export const balanceChangeOperationTypeCode = {
  refill: 'refill',
  payout: 'payout',
};

export const balanceChangeOperationTypes = {
  [balanceChangeOperationTypeCode.refill]: {
    label: 'Refill',
    value: 'refill',
  },
  [balanceChangeOperationTypeCode.payout]: {
    label: 'Payout',
    value: 'payout',
  },
};

export const balanceChangeOperationTypeDictionary = Object.values(
  balanceChangeOperationTypes
);
