<template>
  <d-edit-card
    ref="editForm"
    card-title="Create balance change"
    @submit="onSubmitForm"
  >
    <q-card-section
      :style="{
        maxWidth: '800px',
      }"
      class="col"
    >
      <DAutocomplete
        v-model="form.reason"
        :options="balanceChangeReasonDictionary"
        :use-input="false"
        :rules="[fieldRules.required]"
        :clearable="false"
        dense
        outlined
        emit-value
        map-options
        label="Reason for balance change"
      />

      <div
        v-for="(row, rowIndex) in form.fields"
        :key="rowIndex"
        class="row q-col-gutter-y-sm q-col-gutter-x-lg q-mt-xs"
      >
        <div
          v-for="(field, fieldIndex) in row"
          :key="`${fieldIndex}_${field.name}`"
          :class="[`col-${field.col}`, 'col-grow']"
        >
          <DAutocomplete
            v-if="field.component === 'autocomplete'"
            v-model="field.value"
            v-on="field.listeners || {}"
            :label="field.label"
            :options="field.options"
            :option-value="field.optionValue"
            :option-label="field.optionLabel"
            :rules="field.rules"
            :disabled="field.disabled"
            :readonly="field.readonly"
            :use-input="field.useInput"
            :clearable="false"
            dense
            outlined
            emit-value
            map-options
          />

          <div v-else class="flex items-start">
            <q-input
              v-model="field.value"
              v-on="field.listeners || {}"
              :type="field.type"
              :label="field.label"
              :rules="field.rules"
              :autogrow="field.autogrow"
              :disabled="field.disabled"
              :readonly="field.readonly"
              :style="{
                marginBottom: !field.rules ? '20px' : undefined,
              }"
              dense
              outlined
              clearable
              class="col-grow"
            />

            <q-btn
              v-if="field.tooltipButton"
              :color="field.tooltipButton.color"
              :icon="field.tooltipButton.icon"
              unelevated
              round
              size="xs"
              class="q-ml-md q-mt-sm"
            >
              <q-tooltip content-class="text-center">
                <span v-html="field.tooltipButton.text" />
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>

    <template #actions="{ loading }">
      <d-edit-form-actions :disable="loading" />
    </template>
  </d-edit-card>
</template>

<script>
import {
  balanceChangeOperationTypeDictionary,
  balanceChangeReasonCode,
  balanceChangeReasonDictionary,
} from './config';
import { DAutocomplete } from '@/features/autocomplete';
import { DEditFormActions } from '@/features/edit-form-actions';
import DEditCard from '@/layouts/edit-card';
import {
  balanceChangeController,
  currencyController,
  prepaymentController,
  usersController,
} from '@/shared/api';
import { messages } from '@/shared/messages';
import {
  authUtils,
  isEmpty,
  isPositiveNumber,
  nestedFreeze,
  notify,
} from '@/shared/utils';
import { isNumber } from '@/shared/utils/isNumber';
import { debounce } from 'quasar';

export default {
  components: {
    DEditCard,
    DAutocomplete,
    DEditFormActions,
  },
  data() {
    return {
      dictionary: {
        loaded: false,
        currency: [],
        agents: [],
      },
      form: {
        reason: null,
        fields: [],
      },
    };
  },
  computed: {
    balanceChangeReasonDictionary() {
      return balanceChangeReasonDictionary;
    },
    fieldRules() {
      return {
        required: (value) => !isEmpty(value) || messages.errors.requiredfield,
        positiveNumber: (value) =>
          isPositiveNumber(value) || messages.errors.positiveNumber,
        checkNumber: (value) => isNumber(value) || messages.errors.enterNumber,
      };
    },
    previewPrepaymentField() {
      let previewPrepaymentField = null;

      for (const rowFields of this.form.fields) {
        for (const field of rowFields) {
          if (field.name !== 'previewPrepayment') {
            continue;
          }

          previewPrepaymentField = field;

          break;
        }
      }

      return previewPrepaymentField;
    },
  },
  methods: {
    toggleLoading(value) {
      this.$refs.editForm?.setLoading(value);
    },
    async fetchDictionaries() {
      try {
        const primaryRole = authUtils.getRoleByHierarchy();
        const [currencyResponse, agentsResponse] = await Promise.all([
          currencyController(primaryRole).getCurrency(),
          usersController(primaryRole).agentsForPrepayments(),
        ]);

        this.dictionary.currency = nestedFreeze(currencyResponse.data);
        this.dictionary.agents = nestedFreeze(agentsResponse.data);
      } finally {
        this.dictionary.loaded = true;
      }
    },
    getFormFields({ reason }) {
      return [
        [
          {
            label: 'Agent',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.fines,
              balanceChangeReasonCode.bonuses,
              balanceChangeReasonCode.manualPrePayment,
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
            ],
            component: 'autocomplete',
            options: this.dictionary.agents,
            optionValue: 'id',
            optionLabel: 'username',
            rules: [this.fieldRules.required],
            name: 'agentId',
          },
        ],
        [
          {
            label: 'Currency',
            value: null,
            col: 4,
            allowedToReason: [
              balanceChangeReasonCode.manualPrePayment,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
            ],
            component: 'autocomplete',
            options: this.dictionary.currency,
            optionValue: 'id',
            optionLabel: 'name',
            rules: [this.fieldRules.required],
            name: 'currencyId',
          },
          {
            label: 'Amount',
            value: null,
            col: 8,
            allowedToReason: [
              balanceChangeReasonCode.fines,
              balanceChangeReasonCode.bonuses,
              balanceChangeReasonCode.manualPrePayment,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
            ],
            type: 'number',
            rules: [this.fieldRules.required, this.fieldRules.checkNumber],
            name: 'amount',
          },
          {
            label: 'Amount',
            value: null,
            col: 8,
            allowedToReason: [
              balanceChangeReasonCode.transferFromOneAgentAnother,
            ],
            type: 'number',
            rules: [this.fieldRules.required, this.fieldRules.positiveNumber],
            name: 'amount',
          },
        ],
        [
          {
            label: 'Settlement (%)',
            value: null,
            col: 6,
            allowedToReason: [balanceChangeReasonCode.manualPrePayment],
            type: 'number',
            rules: [this.fieldRules.required],
            name: 'prepaymentPercent',
          },
          {
            label: 'Final amount',
            value: null,
            col: 6,
            allowedToReason: [balanceChangeReasonCode.manualPrePayment],
            name: 'previewPrepayment',
            readonly: true,
          },
        ],
        [
          {
            label: 'Sending agent name',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.transferFromOneAgentAnother,
            ],
            component: 'autocomplete',
            options: this.dictionary.agents,
            optionValue: 'id',
            optionLabel: 'username',
            rules: [this.fieldRules.required],
            name: 'sendingAgentId',
          },
        ],
        [
          {
            label: 'Receiving agent name',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.transferFromOneAgentAnother,
            ],
            component: 'autocomplete',
            options: this.dictionary.agents,
            optionValue: 'id',
            optionLabel: 'username',
            rules: [this.fieldRules.required],
            name: 'receivingAgentId',
          },
        ],
        [
          {
            label: 'User ID',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
            ],
            name: 'userId',
          },
        ],
        [
          {
            label: 'ID merchant transaction',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
            ],
            rules: [this.fieldRules.required],
            name: 'merchantTransactionId',
            tooltipButton: null,
            hasMerchantTransactionId: null,
            listeners: {
              input: this.onInputMerchantTransactionIdField,
              change: this.checkMerchantTransactionIdField,
            },
          },
        ],
        [
          {
            label: 'Operation type',
            value: null,
            col: 12,
            options: balanceChangeOperationTypeDictionary,
            component: 'autocomplete',
            allowedToReason: [
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
            ],
            rules: [this.fieldRules.required],
            useInput: false,
            name: 'operationType',
            listeners: {
              input: this.checkMerchantTransactionIdField,
            },
          },
        ],
        [
          {
            label: 'Comment',
            value: null,
            col: 12,
            allowedToReason: [
              balanceChangeReasonCode.transferFromOneAgentAnother,
              balanceChangeReasonCode.transactionsThatWereAccountedForIncorrectly,
              balanceChangeReasonCode.syncOfBalanceBetweenAgentAndClientMostbet,
              balanceChangeReasonCode.fines,
              balanceChangeReasonCode.bonuses,
              balanceChangeReasonCode.manualPrePayment,
            ],
            type: 'textarea',
            autogrow: true,
            name: 'comment',
            rules: [this.fieldRules.required],
          },
        ],
      ].reduce((acc, row) => {
        const fields = row.filter(({ allowedToReason }) =>
          allowedToReason?.includes(reason)
        );

        if (fields.length) {
          acc.push(fields);
        }

        return acc;
      }, []);
    },
    async onSubmitForm() {
      this.toggleLoading(true);

      const payload = this.form.fields.reduce(
        (acc, fieldRow) => {
          fieldRow.forEach(({ name, value }) => {
            if (name === 'previewPrepayment') {
              return;
            }

            if (name === 'prepaymentPercent') {
              name = 'balanceChangePercent';
            }

            acc[name] = value;
          });

          return acc;
        },
        {
          reason: this.form.reason,
        }
      );

      try {
        const { amount, currency } = await balanceChangeController(
          authUtils.getRoleByHierarchy()
        ).createBalanceChange(payload);

        const message = [
          'The balance of the agent and the Mostbet client has been sync successfully',
          amount && `Amount to change - ${amount} ${currency?.code ?? ''}`,
        ]
          .filter((v) => !isEmpty(v))
          .join('.<br />');

        notify.success(message, { html: true });
        this.$router.push({ name: 'BalanceChangesHistory' });
      } finally {
        this.toggleLoading(false);
      }
    },
    checkLoading() {
      return this.$refs.editForm?.loading ?? false;
    },
    initFormFieldsWatcher() {
      this.$watch(
        () => {
          return [this.form.reason, this.dictionary.loaded].join('-');
        },
        () => {
          if (!this.dictionary.loaded) {
            return;
          }

          const reason = this.form.reason;

          this.form.fields = reason ? this.getFormFields({ reason }) : [];
        },
        {
          immediate: true,
        }
      );
    },
    initMerchantTransactionIdField() {
      this.debouncedCheckMerchantTransactionIdField = debounce(
        this.checkMerchantTransactionIdField,
        3e3,
        {
          maxWait: 3e3,
        }
      );

      this.$once('hook:beforeDestroy', () => {
        this.debouncedCheckMerchantTransactionIdField.cancel();
      });
    },
    initPreviewPrepaymentWatcher() {
      let fetchPreviewPrepaymentJSON = null;

      const fetchPreviewPrepayment = async (
        payloadJSON,
        { ignoreLoadingState }
      ) => {
        fetchPreviewPrepaymentJSON = payloadJSON;

        if (this.checkLoading() && !ignoreLoadingState) {
          return;
        }

        if (!payloadJSON) {
          return;
        }

        const { agentId, currencyId, amount, prepaymentPercent } =
          JSON.parse(payloadJSON);

        if (!agentId || !currencyId || !amount || !prepaymentPercent) {
          return;
        }

        try {
          this.toggleLoading(true);

          const { data } = await prepaymentController(
            authUtils.getRoleByHierarchy()
          ).previewPrepayment({
            agentId,
            currencyId,
            amount,
            prepaymentPercent: prepaymentPercent ?? null,
            comment: '',
          });

          if (this.previewPrepaymentField) {
            this.previewPrepaymentField.value = data.finalAmount;
          }
        } catch (e) {
          notify.error(e.message);
        } finally {
          if (fetchPreviewPrepaymentJSON !== payloadJSON) {
            fetchPreviewPrepayment(fetchPreviewPrepaymentJSON, {
              ignoreLoadingState: true,
            });
          } else {
            this.toggleLoading(false);
          }
        }
      };

      const debouncedPreviewPrepayment = debounce((payloadJSON) => {
        fetchPreviewPrepayment(payloadJSON, {
          ignoreLoadingState: false,
        });
      }, 700);

      this.$watch(
        () => {
          if (!this.previewPrepaymentField) {
            return null;
          }

          const payload = {};

          for (const rowFields of this.form.fields) {
            for (const field of rowFields) {
              if (
                ![
                  'agentId',
                  'currencyId',
                  'amount',
                  'prepaymentPercent',
                ].includes(field.name)
              ) {
                continue;
              }

              payload[field.name] = field.value;
            }
          }

          return JSON.stringify(payload);
        },
        debouncedPreviewPrepayment,
        {
          immediate: true,
        }
      );

      this.$once('hook:beforeDestroy', () => {
        debouncedPreviewPrepayment.cancel();
      });
    },
    findFormFieldByName(name) {
      for (const rowFields of this.form.fields) {
        for (const field of rowFields) {
          if (field.name !== name) {
            continue;
          }

          return field;
        }
      }

      return null;
    },
    initAutoFillPrepaymentPercent() {
      this.$watch(
        () => {
          return this.findFormFieldByName('agentId')?.value;
        },
        (selectedAgentId) => {
          if (!selectedAgentId) {
            return;
          }

          const selectedAgent = this.findFormFieldByName(
            'agentId'
          )?.options.find((option) => option.id === selectedAgentId);

          if (!selectedAgent?.prepaymentPercent) {
            return;
          }

          const prepaymentPercentField =
            this.findFormFieldByName('prepaymentPercent');

          if (!prepaymentPercentField) {
            return;
          }

          prepaymentPercentField.value =
            selectedAgent?.prepaymentPercent ?? null;
        },
        { immediate: true }
      );
    },
    onInputMerchantTransactionIdField() {
      const merchantTransactionIdField = this.findFormFieldByName(
        'merchantTransactionId'
      );

      if (!merchantTransactionIdField) {
        return;
      }

      merchantTransactionIdField.hasMerchantTransactionId = null;
      this.debouncedCheckMerchantTransactionIdField();
    },
    async checkMerchantTransactionIdField() {
      this.debouncedCheckMerchantTransactionIdField.cancel();

      if (this.checkLoading()) {
        return;
      }

      const merchantTransactionIdField = this.findFormFieldByName(
        'merchantTransactionId'
      );

      if (!merchantTransactionIdField?.value) {
        return;
      }

      const operationTypeField = this.findFormFieldByName('operationType');

      if (!operationTypeField?.value) {
        return;
      }

      this.toggleLoading(true);

      try {
        const { hasMerchantTransactionId } = await balanceChangeController(
          authUtils.getRoleByHierarchy()
        ).checkMerchantTransactionId({
          merchantTransactionId: merchantTransactionIdField.value,
          operationType: operationTypeField.value,
        });

        merchantTransactionIdField.tooltipButton = hasMerchantTransactionId
          ? {
              color: 'warning',
              text: `For this transaction, the agent's<br />balance has already been changed`,
              icon: 'mdi-alert-circle-outline',
            }
          : null;

        merchantTransactionIdField.hasMerchantTransactionId =
          hasMerchantTransactionId;
      } catch {
        merchantTransactionIdField.tooltipButton = null;
        merchantTransactionIdField.hasMerchantTransactionId = false;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
  created() {
    this.initFormFieldsWatcher();
    this.initPreviewPrepaymentWatcher();
    this.initAutoFillPrepaymentPercent();
    this.initMerchantTransactionIdField();
  },
  async mounted() {
    this.toggleLoading(true);

    try {
      await this.fetchDictionaries();
    } finally {
      this.toggleLoading(false);
    }
  },
};
</script>
